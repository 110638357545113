import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiFillEdit, AiFillDelete } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Cookies from "universal-cookie";
import { FormControl } from "@mui/material";

const Makes = ({ url }) => {
    const [makes, setMakes] = useState([]);
    const [body, setBody] = useState({
        title: "",
        id: ""
    });

    const [mode, setMode] = useState(null);
    const cookies = new Cookies();
    const token = cookies.get("jwt");
    const getMakes = async () => {
        try {
            const response = await fetch(`${url}/makes/all`);
            if (response.ok) {
                const data = await response.json();
                setMakes(data.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const createFeatureCategory = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${url}/makes/create`, {
                method: "POST",
                body: JSON.stringify({ title: body.title }),
                headers: {
                    authorization: `${token}`,
                    "Content-Type": "application/json",
                },
            });
            if (response.ok) {
                getMakes();
            }

            setMode(null);
            setBody({
                title: "",
                id: ""
            });

        } catch (err) {
            console.log(err);
        }
    };

    const editFeatureCategory = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${url}/makes/${body.id}`, {
                method: "PATCH",
                body: JSON.stringify({ title: body.title }),
                headers: {
                    authorization: token,
                    "Content-Type": "application/json",
                },
            });
            if (response.ok) {
                getMakes();
                // setEditValue("");
            }

            setMode(null);
            setBody({
                title: "",
                id: ""
            });

        } catch (err) {
            console.log(err);
        }
    };

    const deleteFeature = async (id) => {
        try {
            const response = await fetch(`${url}/makes/${id}`, {
                method: "DELETE",
                headers: {
                    authorization: token,
                },
            });
            if (response.ok) {
                getMakes();
            }
            setMode(null);
            setBody({
                title: "",
                id: ""
            });
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getMakes();
    }, []);

    const EditComponent = ({ feature, setMode }) => {

        const handleEdit = (e, body) => {
            e.preventDefault();
            setMode("edit")
            setBody({
                title: body.title,
                id: body._id
            })
        }
        return (
            <>
                <button
                    style={{ textTransform: "capitalize" }}
                    className={feature.selected ? "feature-selected" : null}
                    id={feature._id}
                    type="button"
                    key={feature._id}
                >
                    <div>{feature.title}</div>
                    <div id="btns">
                        <span onClick={(e) => handleEdit(e, feature)}>
                            <AiFillEdit />
                        </span>
                        <span onClick={() => deleteFeature(feature._id)}>
                            <AiFillDelete />
                        </span>
                    </div>
                </button >

            </>
        )
    };
    return (
        <div>
            <div className="select-features">
                <div className="features-header">
                    <h3 className="text-left">All Makes</h3>
                    {/* {add ? (
                        <></>
                    ) : ( */}
                    <div className="text-left mb-4">
                        <button onClick={() => {
                            setMode("add");
                            setBody({
                                title: "",
                                id: ""
                            });
                        }} className="btn btn-primary">
                            + Create a new make
                        </button>
                    </div>
                    {/* )} */}
                </div>
                {mode && <div className="align-items-center mb-4 w-100">
                    <form onSubmit={mode == "add" ? createFeatureCategory : editFeatureCategory}>
                        <div className="row w-100">
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormControl variant="standard" fullWidth>

                                            <TextField
                                                fullWidth
                                                value={body.title}
                                                sx={{ "& > input": { fontSize: "16px" } }}
                                                onChange={(e) => setBody({
                                                    ...body,
                                                    [e.target.name]: e.target.value
                                                })}
                                                id="standard-basic"
                                                label="Make Title"
                                                variant="standard"
                                                name="title"
                                            />
                                        </FormControl>
                                    </div>

                                    <div className="col-md-12 mt-4">
                                        <div className="icon text-left">
                                            <button type="submit" className="btn btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </form>

                </div>}
                <div className="features">
                    {makes &&
                        makes.length > 0 &&
                        makes.sort().map((feature) => {
                            return (
                                <div key={feature._id}>
                                    <EditComponent feature={feature} mode={mode} setMode={setMode} />
                                </div>
                            );
                        })}
                </div>
            </div >
        </div >
    );
};

export default Makes;