import React, { useEffect, useRef, useState } from "react";

import "./accordion.css";

import { ReactComponent as DownArrow } from "../../images/svgs/down-arrow.svg";

import { AiFillEdit, AiFillDelete } from "react-icons/ai";

const FeatureElements = ({ features,
    setBody,
    setMode,
    deleteFeature
}) => {

    const handleEdit = (e, body) => {
        e.preventDefault();
        setMode("edit")
        setBody({
            title: body.title,
            category_id: body.category_id ? body.category_id._id: "",
            id: body._id
        })
    }

    return (
        <div className="row">
            {
                features && features.map((feature) => {
                    return (
                        <button
                            style={{ textTransform: "capitalize" }}
                            className={feature.selected ? "feature-selected" : null}
                            id={feature._id}
                            type="button"
                            key={feature._id}
                        >
                            <div>{feature.title}</div>
                            <div id="btns">
                                <span onClick={(e) => {
                                    handleEdit(e, feature)
                                }}>
                                    <AiFillEdit />
                                </span>
                                <span onClick={() => deleteFeature(feature._id)}>
                                    <AiFillDelete />
                                </span>
                            </div>
                        </button>
                    )
                })
            }
        </div>
    )
}

function Accordion(props) {
    const [active, setActive] = useState(true);
    const content = useRef(null);
    const [height, setHeight] = useState("0px");

    useEffect(() => {
        console.log("Height for ", props.item.title, ": ", height);
    }, [height]);

    function toggleAccordion() {
        setActive(!active);
        props.setSelected(props.index)
        setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
    }

    useEffect(() => {
        if (props.selected != props.index) {
            setHeight("0px");
            setActive(false)
        }
        if (props.selected == props.index) {
            setHeight(`max-content`)
        }
    }, [props.selected])


    return (
        <div className="accordion__section w-100">
            <div
                className={`accordion bottom-b ${active ? "active" : ""}`}
                onClick={toggleAccordion}
            >
                <p className="accordion__title">{props.item.title} <p className="descr">{props.item.description}</p></p>
                <span style={{ marginLeft: "20px" }}>
                    {active ?
                        <DownArrow />
                        :
                        <DownArrow />
                    }

                </span>
            </div>
            <div
                ref={content}
                style={{ maxHeight: `${height}` }}
                className="accordion__content"
            >
                <div
                    className="accordion__text"

                >
                    <FeatureElements features={props.item.features_list}
                        setMode={props.setMode}
                        setBody={props.setBody}
                        deleteFeature={props.deleteFeature}
                    // selectFeature={props.selectFeature}
                    />
                </div>
            </div>
        </div>
    );
}

export default Accordion;