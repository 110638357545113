import React, { useState, useEffect } from "react";
import { getWavs } from "../../redux/action.js";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import moment from "moment";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { toast } from "react-toastify";

const VehicleListing = ({ url, setSelected, setSelectedWav }) => {
  const [searchValue, setSearchValue] = useState("");
  const cookies = new Cookies();
  const token = cookies.get("jwt");
  const [status, setStatus] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWavs());
  }, []);
  let wavs = useSelector((state) => state.admin.wavs);

  const deleteWav = async (id) => {
    try {
      const response = await fetch(`${url}/wav/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `${token}`,
        },
      });
      if (response.ok) {
        dispatch(getWavs());
      } else {
        console.log("error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };
  // const filtered =
  //   wavs &&
  //   wavs.filter((wav) =>
  //     wav.stock_id.toLowerCase().includes(searchValue.toLowerCase())
  //   );
  // const sortedByStatus =
  //   status === "" ? filtered : filtered.filter((wav) => wav.status === status);

  const [filtered, setFiltered] = useState([]);

  const [sortedByStatus, setSortedByStatus] = useState(filtered)

  useEffect(() => {
    if (wavs) {
      let filteredArr = wavs.filter((wav) => wav.stock_id.toLowerCase().includes(searchValue.toLowerCase()));
      setFiltered(filteredArr)
      setSortedByStatus((status.length == 0) ? filteredArr : filteredArr.filter((wav) => status.includes(wav.status)))
    }
  }, [wavs])

  const SingleWav = ({ item, i }) => {
    const [featured, setFeatured] = useState(item.featured);
    const updateWav = async (id) => {
      let formData = new FormData();
      if (!featured) {
        formData.append("featured", true);
      } else {
        formData.append("featured", false);
      }
      const mainUrl = `${url}/wav/${id}`;
      const method = "PATCH";
      try {
        const response = await fetch(mainUrl, {
          method,
          body: formData,
          headers: {
            authorization: `${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log("data:", data);
          dispatch(getWavs());
        } else {
          const data = await response.json();
          console.log("error data: ", data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const toggleWavStatus = (id, status) => {
      let updatedArr = sortedByStatus.map((ele) => {
        if (ele._id == id) {
          ele.status = status
        }
        return ele
      })
      setSortedByStatus(updatedArr)
    }

    const toggleStatus = async (id) => {
      let formData = new FormData();
      formData.append("status", item.status == "active" ? "inactive" : "active");
      const mainUrl = `${url}/wav/${id}`;
      const method = "PATCH";
      try {
        const response = await fetch(mainUrl, {
          method,
          body: formData,
          headers: {
            authorization: `${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          toast.success("Status updated successfully")
          toggleWavStatus(id, item.status == "active" ? "inactive" : "active")
        } else {
          const data = await response.json();
          toast.error("Status doesnot updated")
        }

      }
      catch (err) {
        console.log(err);
      }
    }

    return (
      <>
        <th scope="row">
          {/* <input type="checkbox" /> */}
          {i + 1}
        </th>
        <td>
          <img
            src={`https://dev.wavcompare.com/uploads/${item.images[0]}`}
            alt="car logo"
            loading="lazy"
          />
        </td>
        <td>{item.models}</td>
        <td>{item.condition}</td>
        <td>{item.price}$</td>
        <td>{item.stock_id}</td>
        <td>
          {moment(new Date(item.created_at)).format("MMMM Do YYYY, h:mm:ss a")}
        </td>
        <td
          onClick={() => {
            setFeatured(!featured);

            updateWav(item._id);
          }}
        >
          {featured ? (
            <AiFillHeart size={30} color="red" />
          ) : (
            <AiOutlineHeart size={30} />
          )}
        </td>
        <td style={{ display: "flex", flexDirection: "column" }}>
          <button
            style={{ fontSize: "12px", textTransform: "capitalize" }}
            className={
              item.status === "active"
                ? "btn btn-success btn-sm mb-2"
                : "btn btn-secondary btn-sm mb-2"
            }
            onClick={()=>toggleStatus(item._id)}
          >
            {item.status}
          </button>
          <button
            onClick={() => {
              setSelectedWav(item);
              setSelected("edit-vehicle-listing");
            }}
            style={{ fontSize: "12px" }}
            className="btn btn-primary btn-sm mb-2"
          >
            Edit
          </button>
          <button
            onClick={() => deleteWav(item._id)}
            style={{ fontSize: "12px" }}
            className="btn btn-danger btn-sm"
          >
            Delete
          </button>
        </td>
      </>
    );
  };
  return (
    <div>
      <h2>ADMIN DASHBOARD</h2>
      <div className="search-and-next">
        <div className="search d-flex align-items-center">
          <input
            className="form-control mr-sm-2"
            type="search"
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder="Search by Vehicle Reg. No."
            aria-label="Search"
          />
          {/* <button className="btn btn-outline-success ml-2 my-2 my-sm-0">
            <FaSearch />
          </button> */}
        </div>
        {/* <div className="prev-or-next">
          <div className="prev-wrapper">
            <img src="/assets/left.png" alt="" />
            <img src="/assets/back.png" alt="" />
          </div>
          <div className="text">1</div>
          <div className="next-wrapper">
            <img src="/assets/next.png" alt="" />
            <img src="/assets/right.png" alt="" />
          </div>
        </div> */}
      </div>
      <div className="lists my-4">
        <div onClick={() => setStatus("")} className="list-item">
          All Listings
        </div>
        <div onClick={() => setStatus("active")} className="list-item">
          Published
        </div>
        <div onClick={() => setStatus("ended")} className="list-item">
          Closed Listings
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Image</th>
            <th scope="col">Model</th>
            <th scope="col">Condtion</th>
            <th scope="col">Price</th>
            <th scope="col">Vehicle Reg. No.</th>
            <th scope="col">Date</th>
            <th scope="col">Featured</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {wavs &&
            sortedByStatus.map((item, i) => (
              <tr key={item._id}>
                <SingleWav item={item} i={i} />
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default VehicleListing;
