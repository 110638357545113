import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiFillEdit, AiFillDelete } from "react-icons/ai";
import TextField from "@mui/material/TextField";

import Cookies from "universal-cookie";
import FeaturesComponent from "./FeaturesComponent";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";

const Models = ({ url }) => {
  const [models, setModels] = useState([]);
  const [makes, setMakes] = useState([]);
  const [body, setBody] = useState({
    title: "",
    make_id: "",
    id: ""
  });
  const [mode, setMode] = useState(null);
  const cookies = new Cookies();
  const token = cookies.get("jwt");
  const getModels = async () => {
    try {
      const response = await fetch(`${url}/models/all`);
      if (response.ok) {
        const data = await response.json();
        setModels(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(body)

  const getMakes = async () => {
    try {
      const response = await fetch(`${url}/makes/all`);
      if (response.ok) {
        const data = await response.json();
        setMakes(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createFeature = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${url}/models/create`, {
        method: "POST",
        body: JSON.stringify({ title: body.title, make_id: body.make_id }),
        headers: {
          authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        getModels();
      }
      setMode(null);
      setBody({
        title: "",
        make_id: "",
        id: ""
      });
    } catch (err) {
      console.log(err);
    }
  };

  const editFeature = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(`${url}/models/${body.id}`, {
        method: "PATCH",
        body: JSON.stringify({ title: body.title, make_id: body.make_id }),
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        getModels();
      }
      setMode(null);
      setBody({
        title: "",
        make_id: "",
        id: ""
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteFeature = async (id) => {
    try {
      const response = await fetch(`${url}/models/${id}`, {
        method: "DELETE",
        headers: {
          authorization: token,
        },
      });
      if (response.ok) {
        getModels();
      }
      setBody({
        title: "",
        make_id: "",
        id: ""
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    (async () => {
      await Promise.all([getModels(),
      getMakes()])
    })();

  }, []);

  const EditComponent = ({ feature }) => {

    const handleEdit = (e, body) => {
      e.preventDefault();
      setMode("edit")
      setBody({
        title: body.title,
        make_id: body?.make_id?._id,
        id: body._id
      })
    }

    return <button
      style={{ textTransform: "capitalize" }}
      className={feature.selected ? "feature-selected" : null}
      id={feature._id}
      type="button"
      key={feature._id}
    >
      <div>{feature.title}</div>
      <div id="btns">
        <span onClick={(e) => {
          handleEdit(e, feature)
        }}>
          <AiFillEdit />
        </span>
        <span onClick={() => deleteFeature(feature._id)}>
          <AiFillDelete />
        </span>
      </div>
    </button>
  };
  return (
    <div>
      <div className="select-features">
        <div className="features-header">
          <h3 className="text-left">All Models</h3>
          {/* {add ? (
                        <></>
                    ) : ( */}
          <div className="text-left mb-4">
            <button onClick={() => {
              setMode("add");
              setBody({
                title: "",
                make_id: "",
                id: ""
              });
            }} className="btn btn-primary">
              + Create a new model
            </button>
          </div>
          {/* )} */}
        </div>
        {mode && <div className="align-items-center mb-4 w-100">
          <form onSubmit={mode == "add" ? createFeature : editFeature}>
            <div className="row w-100">
                <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-12">
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              fullWidth
                              value={body.title}
                              sx={{ "& > input": { fontSize: "16px" } }}
                              onChange={(e) => setBody({ ...body, [e.target.name]: e.target.value })}
                              id="standard-basic"
                              label="Model Title"
                              variant="standard"
                              name="title"
                            />
                          </FormControl>

                        </div>

                        <div className="col-md-12 mt-3">
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Select Make</InputLabel>
                            <Select
                              autoWidth
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={body.make_id}
                              onChange={(e) => setBody({ ...body, [e.target.name]: e.target.value })}
                              label="Select Make"
                              name="make_id"

                            >
                              <MenuItem value="" disabled>
                                <em>Select Make</em>
                              </MenuItem>
                              {
                                makes.map((ele) => {
                                  return (
                                    <MenuItem value={ele._id}>{ele.title}</MenuItem>
                                  )
                                })
                              }
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-md-12 mt-4">
                          <div className="icon text-left">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                      </div>
                    </div>
                </div>
            </div>


          </form>

        </div>}

        <div className="features">
          {models &&
            models.length > 0 &&
            models.sort().map((feature) => {
              return (
                <div key={feature._id}>
                  <EditComponent feature={feature} mode={mode} setMode={setMode} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Models;
