import { LOGIN, LOGOUT, GET_DEALERS, GET_WAVS, GET_DEALER, GET_MAKES, GET_MODELS } from "./constants";
import Cookies from "universal-cookie";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;

export const loginAdmin = (admin) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/admin/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(admin),
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: LOGIN, payload: data.data.token });
        const cookies = new Cookies();
        const today = new Date();
        const tomorrow = new Date(today);
        const expiryDate = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
        cookies.set("jwt", data.data.token, { expires: expiryDate });
        cookies.set("user", data.data.admin, { expires: expiryDate });
        return { status: "success", id: data.data.admin._id };
      } else {
        const data = await response.json();
        console.log("error: ", data.error);
        return { status: "error", error: data.error };
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const logoutadmin = () => {
  return async (dispatch) => {
    try {
      const cookies = new Cookies();
      cookies.remove("jwt");
      cookies.remove("user");

      dispatch({ type: LOGOUT });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDealers = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/dealer/all`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_DEALERS, payload: data.data.dealers });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDealer = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/dealer/${id}`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_DEALER, payload: data.data.dealer });
        return data.data.dealer;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getWavs = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/wav/all`, {
        method: "POST",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_WAVS, payload: data.data.wavs });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


export const getMakes = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/makes/active/all`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_MAKES, payload: data.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getModels = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/models/active/all`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_MODELS, payload: data.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};