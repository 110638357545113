import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { getDealers } from "../../redux/action.js";
const DealerListing = ({ setSelected, setSelectedUser, url }) => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const dealersFromState = useSelector((state) => state.admin.dealers);
  const cookies = new Cookies();
  const token = cookies.get("jwt");
  useEffect(() => {
    dispatch(getDealers());
  }, []);
  const deleteDealer = async (id) => {
    try {
      const response = await fetch(`${url}/dealer/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `${token}`,
        },
      });

      if (response.ok) {
        dispatch(getDealers());
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filtered = dealersFromState.filter((dealer) =>
    dealer.name.toLowerCase().includes(searchValue)
  );
  return (
    <div>
      <h2>ADMIN DASHBOARD</h2>
      <div className="search-and-next mb-5">
        <div className="search d-flex align-items-center">
          <input
            className="form-control mr-sm-2"
            type="search"
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder="Search By Name"
            aria-label="Search"
          />
        </div>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Logo</th>
            <th scope="col">Name</th>
            <th scope="col">Address</th>
            <th scope="col">Role</th>
            <th scope="col">Phone</th>
            <th scope="col">Email</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {filtered.map((dealer, i) => (
            <tr key={dealer._id}>
              <th scope="row">{i + 1}</th>
              <td>
                <img
                  src={
                    dealer.logo
                      ? `https://dev.wavcompare.com/uploads/${dealer.logo}`
                      : "/assets/user.jpg"
                  }
                  alt="car logo"
                  loading="lazy"
                />
              </td>
              <td>{dealer.name}</td>
              <td>
                <div>
                  {dealer && dealer.address ? dealer.address : "not specified"}
                </div>
              </td>
              <td style={{ textTransform: "capitalize" }}>{dealer.role}</td>
              <td>{dealer.phone_number}</td>
              <td>{dealer.email}</td>
              <td style={{ display: "flex", flexDirection: "column" }}>
                <button
                  onClick={() => {
                    setSelected("edit-dealer-listing");
                    setSelectedUser(dealer);
                  }}
                  style={{ fontSize: "12px" }}
                  className="btn btn-primary btn-sm mb-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => deleteDealer(dealer._id)}
                  style={{ fontSize: "12px" }}
                  className="btn btn-danger btn-sm"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DealerListing;
