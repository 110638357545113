import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { adminReducer } from "./reducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // You can choose the storage engine you want to use


// Configure the persist options
const persistConfig = {
  key: 'root',
  storage,
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const initialStore = {
  admin: {
    authenticated: false,
    token: "",
    dealers: [],
    wavs: [],
    selected: {},
    makes: [],
    models: [],
  },
};

const bigReducer = combineReducers({
  admin: adminReducer,
});

const persistedReducer = persistReducer(persistConfig, bigReducer);


const configureStore = createStore(
  persistedReducer,
  initialStore,
  composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(configureStore);

export { configureStore, persistor };
