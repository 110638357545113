import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiFillEdit, AiFillDelete } from "react-icons/ai";
import TextField from "@mui/material/TextField";

import Cookies from "universal-cookie";
import FeaturesComponent from "./FeaturesComponent";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";

const Features = ({ url }) => {
  const [features, setFeatures] = useState([]);
  const [featuresCategories, setFeaturesCategories] = useState([]);
  const [body, setBody] = useState({
    title: "",
    category_id: "",
    id: ""
  });
  const [mode, setMode] = useState(null);
  const cookies = new Cookies();
  const token = cookies.get("jwt");
  const getFeatures = async () => {
    try {
      const response = await fetch(`${url}/features/all`);
      if (response.ok) {
        const data = await response.json();
        setFeatures(data.data.features);
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(body)

  const getFeaturesCategories = async () => {
    try {
      const response = await fetch(`${url}/features/category/all`);
      if (response.ok) {
        const data = await response.json();
        setFeaturesCategories(data.data.featuresCategories);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createFeature = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${url}/features/create`, {
        method: "POST",
        body: JSON.stringify({ title: body.title, category_id: body.category_id }),
        headers: {
          authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        getFeatures();
      }
      setMode(null);
      setBody({
        title: "",
        category_id: "",
        id: ""
      });
    } catch (err) {
      console.log(err);
    }
  };

  const editFeature = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(`${url}/features/${body.id}`, {
        method: "PATCH",
        body: JSON.stringify({ title: body.title, category_id: body.category_id }),
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        getFeatures();
      }
      setMode(null);
      setBody({
        title: "",
        category_id: "",
        id: ""
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteFeature = async (id) => {
    try {
      const response = await fetch(`${url}/features/${id}`, {
        method: "DELETE",
        headers: {
          authorization: token,
        },
      });
      if (response.ok) {
        getFeatures();
      }
      setBody({
        title: "",
        category_id: "",
        id: ""
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    (async () => {
      await Promise.all([getFeatures(),
      getFeaturesCategories()])
    })();

  }, []);

  const EditComponent = ({ feature }) => {

    const handleEdit = (e, body) => {
      e.preventDefault();
      setMode("edit")
      setBody({
        title: body.title,
        category_id: body.category_id,
        id: body._id
      })
    }

    return <button
      style={{ textTransform: "capitalize" }}
      className={feature.selected ? "feature-selected" : null}
      id={feature._id}
      type="button"
      key={feature._id}
    >
      <div>{feature.title}</div>
      <div id="btns">
        <span onClick={(e) => {
          handleEdit(e, feature)
        }}>
          <AiFillEdit />
        </span>
        <span onClick={() => deleteFeature(feature._id)}>
          <AiFillDelete />
        </span>
      </div>
    </button>
  };
  return (
    <div>
      <div className="select-features">
        <div className="features-header">
          <h3 className="text-left">All Features</h3>
          {/* {add ? (
                        <></>
                    ) : ( */}
          <div className="text-left mb-4">
            <button onClick={() => {
              setMode("add");
              setBody({
                title: "",
                category_id: "",
                id: ""
              });
            }} className="btn btn-primary">
              + Create a new feature
            </button>
          </div>
          {/* )} */}
        </div>
        {mode && <div className="align-items-center mb-4 w-100">
          <form onSubmit={mode == "add" ? createFeature : editFeature}>
            <div className="row w-100">
                <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-12">
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              fullWidth
                              value={body.title}
                              sx={{ "& > input": { fontSize: "16px" } }}
                              onChange={(e) => setBody({ ...body, [e.target.name]: e.target.value })}
                              id="standard-basic"
                              label="Feature Title"
                              variant="standard"
                              name="title"
                            />
                          </FormControl>

                        </div>

                        <div className="col-md-12 mt-3">
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Select Category</InputLabel>
                            <Select
                              autoWidth
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={body.category_id}
                              onChange={(e) => setBody({ ...body, [e.target.name]: e.target.value })}
                              label="Select Category"
                              name="category_id"

                            >
                              <MenuItem value="" disabled>
                                <em>Select Category</em>
                              </MenuItem>
                              {
                                featuresCategories.map((ele) => {
                                  return (
                                    <MenuItem value={ele._id}>{ele.title}</MenuItem>
                                  )
                                })
                              }
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-md-12 mt-4">
                          <div className="icon text-left">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                      </div>
                    </div>
                </div>
            </div>


          </form>

        </div>}
        <FeaturesComponent features={features} setMode={setMode} setBody={setBody} deleteFeature={deleteFeature} />
        {/* <div className="features">
          {features &&
            features.length > 0 &&
            features.sort().map((feature) => {
              return (
                <div key={feature._id}>
                  <EditComponent feature={feature} />
                </div>
              );
            })}
        </div> */}
      </div>
    </div>
  );
};

export default Features;
