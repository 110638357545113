import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiFillEdit } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Cookies from "universal-cookie";
const Packages = ({ url }) => {
  const [packages, setPackages] = useState([]);
  const [individual, setIndividual] = useState("");
  const [packeg_1, setPackeg_1] = useState("");
  const [packeg_2, setPackeg_2] = useState("");
  const [packeg_3, setPackeg_3] = useState("");

  const cookies = new Cookies();
  const token = cookies.get("jwt");
  const getPackages = async () => {
    try {
      const response = await fetch(`${url}/price/get`);
      if (response.ok) {
        const data = await response.json();
        const packs = data.data.price[0];
        setIndividual(packs.individual);
        setPackeg_1(packs.packeg_1);
        setPackeg_2(packs.packeg_2);
        setPackeg_3(packs.packeg_3);
        setPackages(data.data.price[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  const EditComponent = ({
    name,
    individualValue,
    packeg1Value,
    package2Value,
    package3Value,
    id,
  }) => {
    const [edit, setEdit] = useState(false);
    const [individual, setIndividual] = useState(
      individualValue ? individualValue : ""
    );
    const [packeg_1, setPackeg_1] = useState(packeg1Value ? packeg1Value : "");
    const [packeg_2, setPackeg_2] = useState(
      package2Value ? package2Value : ""
    );
    const [packeg_3, setPackeg_3] = useState(
      package3Value ? package3Value : ""
    );

    const editPackage = async (id) => {
      try {
        const response = await fetch(`${url}/price/${id}`, {
          method: "PATCH",
          body: JSON.stringify({
            individual,
            packeg_1,
            packeg_2,
            packeg_3,
          }),
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          getPackages();
        }
      } catch (err) {
        console.log(err);
      }
    };

    const handleChange = (e) => {
      if (name === "Individual") {
        setIndividual(e.target.value);
      }
      if (name === "Package 1") {
        setPackeg_1(e.target.value);
      }
      if (name === "Package 2") {
        setPackeg_2(e.target.value);
      }
      if (name === "Package 3") {
        setPackeg_3(e.target.value);
      }
    };
    let title =
      name === "Individual"
        ? individual
        : name === "Package 1"
        ? packeg_1
        : name === "Package 2"
        ? packeg_2
        : packeg_3;

    return edit ? (
      <div className="d-flex align-items-center mx-4">
        <div>
          <TextField
            value={
              name === "Individual"
                ? individual
                : name === "Package 1"
                ? packeg_1
                : name === "Package 2"
                ? packeg_2
                : packeg_3
            }
            sx={{ "& > input": { fontSize: "16px" } }}
            onChange={handleChange}
            id="standard-basic"
            label="Edit the package"
            variant="standard"
          />
        </div>
        <div
          onClick={() => {
            editPackage(id);
            setEdit(false);
          }}
          className="icon"
        >
          <AiOutlinePlus />
        </div>
      </div>
    ) : (
      <button style={{ textTransform: "capitalize" }} type="button">
        <div>
          {name}
          <span
            style={{
              background: "red",
              padding: "5px",
              color: "#fff",
              borderRadius: "50%",
              marginLeft: "3rem",
            }}
          >
            {title}£
          </span>
        </div>
        <div id="btns">
          <span onClick={() => setEdit(true)}>
            <AiFillEdit />
          </span>
        </div>
      </button>
    );
  };
  return (
    <div>
      <div className="select-features">
        <div className="features-header">
          <h3>All Packages</h3>
        </div>
        <div className="features">
          <div>
            <EditComponent
              individualValue={individual}
              packeg1Value={packeg_1}
              package2Value={packeg_2}
              package3Value={packeg_3}
              name="Individual"
              id={packages._id}
            />
          </div>
          <div>
            <EditComponent
              name="Package 1"
              individualValue={individual}
              packeg1Value={packeg_1}
              package2Value={packeg_2}
              package3Value={packeg_3}
              id={packages._id}
            />
          </div>
          <div>
            <EditComponent
              name="Package 2"
              individualValue={individual}
              packeg1Value={packeg_1}
              package2Value={packeg_2}
              package3Value={packeg_3}
              id={packages._id}
            />
          </div>
          <div>
            <EditComponent
              name="Package 3"
              individualValue={individual}
              packeg1Value={packeg_1}
              package2Value={packeg_2}
              package3Value={packeg_3}
              id={packages._id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
